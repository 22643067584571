import Arg from '@livongo/arg';
import {cleanSessionInfo} from 'utilities/utils';
import {
    mixpanelUserSSOSuccessful,
    mixpanelUserSSOFailed,
} from 'utilities/mixpanel-utils';
import {amplitudeFlag, AmplitudeUtils} from 'utilities/amplitude-utils';

const arg = Arg.all();
// delete this or change to ture when ticket CP-2856 ready
const errorPageToggleOn = false;

export const handleSSOFailed = () => {
    cleanSessionInfo(true);
    mixpanelUserSSOFailed();

    if (amplitudeFlag) {
        AmplitudeUtils.trackUserSSOFailed();
    }

    if (arg.oneapp) {
        errorPageToggleOn
            ? (window.location.href = `${window.location.origin}/info${window.location.search}&info=ssoError`)
            : (window.location.href = `${process.env.ONEAPP_REG_FLOW_URL}${window.location.search}&fromSso=false`);
    } else {
        window.location.href = `${process.env.REG_FLOW_URL}${window.location.search}&fromSso=false`;
    }
};

export const handleSSOSuccess = () => {
    mixpanelUserSSOSuccessful();

    if (amplitudeFlag) {
        AmplitudeUtils.trackUserSSOSuccessful();
    }

    if (arg.oneapp) {
        window.location.href = `${process.env.ONEAPP_REG_FLOW_URL}${window.location.search}&fromSso=true`;
    } else {
        window.location.href = `${process.env.REG_FLOW_URL}${window.location.search}&fromSso=true`;
    }
};
